.navbar {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  justify-content: space-between;
  padding: 0 0;
  align-items: center;
  background-color: #fff;
}
.navbar__left {
  display: flex;
  align-items: center;
}
.navbar__left img {
  margin-right: 20px;
  margin-left: 20px;
}
.navbar__left span {
  font-size: 20px;
  font-weight: bold;
}
.navbar__right {
  display: flex;
  align-items: center;
}
.navbar__logo {
  height: 30px;
  width: auto;
  margin-right: 0.5em;
}

.jitsi-credit {
  display: inline-block;
  color: #888;
  margin-left: 1em;
  font-size: 0.9em;
}
