.blur-back {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: -25%;
  margin-left: -50%;
  width: 200%;
  height: 200%;
  background-color: transparent;
  filter: blur(10px);
  -webkit-filter: blur(10px); /* Safari 6.0 - 9.0 */
  background-image: url('/public/stage_game.jpg');
  background-size: 100%;
  background-position: top left;
  background-repeat: no-repeat;
  background-color: #000;
}
.blur-front {
  width: 100%;
  height: 100%;
}
.blur-front .dialog {
  position: relative;
  width: 40%;
  height: 40%;
  z-index: 10000;
  background-color: #fff;
  margin: 5% auto;
  border: 2px solid #660;
  border-radius: 30px;
  color: #090;
  padding: 2em;
  font-size: 1.5em;
  box-sizing: content-box;
}
