* {
  box-sizing: border-box;
}
.videoStream {
  max-width: 300px;
}
.merseyside-team {
  /* wraps the whole team side */
  /* not in use ? */
  width: 35%;
  height: 92vh;
  margin-top: 1%;
}

div.team-placement {
  /* wraps the video slot */
  z-index: 100;
  max-width: 50%;
  min-width: 10%;
  max-height: 50%;
  min-height: 50%;

  border-radius: 20px;
  overflow: hidden;
}

#team-1 {
  /* background-color: rgba(255, 100, 100, 0.2); */
}
#team-2 {
  /* background-color: rgba(100, 100, 255, 0.2); */
}
.merseyside-flexwrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  flex-wrap: wrap;
}

.merseyside-podium {
  /* wraps the whole player card */
  flex-basis: 45%; /* this controls how much space the whole card takes up */

  border: 2px solid rgba(120,160,160,0.8);
  padding:0px;

  border-radius: 20px;
  overflow: hidden;
}
.gameroom {
  height: 100%;
  width: 100%;
  background-color: #f00;
}
.merseyside-gameboard {
  height: 90vh;
  width: 100%;
  min-height: 90vh;
  display: flex;
  box-sizing: border-box;
}
div.merseyside-team-placement {
  position: relative;
  z-index: 100;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.your-team {

}

#host-podium {

}
.merseyside-host {
  position: fixed;
  left: 40vw;
  width: 20vw;
  height: 20vh;
  bottom: 30vh;
  vertical-align: baseline;
  margin-top: 10px;
  background-color: transparent;
}

.merseyside-host .merseyside-host-podium .merseyside-host-placement {
  display: flex;
  align-items: middle;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.merseyside-host-placement video {
  width: 100%;

}

.merseyside-host-podium {

  background-color: #002020;
  width: 100%;
  border: 2px solid #fff;
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
}

@font-face{
 font-family:'digital-clock-font';
 src: url('/public/minecart_lcd.ttf');
 font-size: 40px;
}

.scoreboard-flex {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 4px 0;
}
.team-header {
  height: 12vh;
  background-color: #f22;
  border: 1px solid #900;
  border-radius: 10px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.led-scoreboard {
  flex-basis: 100%;
  font-family: 'digital-clock-font';
  background-color: #000;
  width: 94%;
  height: 1em;
  line-height: 8vh;
  font-size: 3vmax;
  color: #f60;
  border: 1px solid #930;
  border-radius: 8px;
  font-weight: normal;
  background-color: #111;
  text-shadow: 1px 1px #633;
}

.score-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
.score-buttons * button {
  font-size: 1.2em;
  width: 2em;
  border-radius: 4px;
  border: 1px solid #000;
  box-sizing: border-box;
  margin: 2px;
}
.score-buttons .good-score button {
  background-color: #8f7;
}
.score-buttons .bad-score button {
  background-color: #f87;
}


.chatbox-handle {
  position: relative;
  width: 32vw;
  font-size: 15px;
  border-radius: 12px;
  border: 1px solid #000;
  box-sizing: border-box;
  z-index: 5000;
  padding: 6px;
  font-weight: bold;
  background-color: rgba(70,255,240,0.5);
  cursor: move;
}
.chatbox-handle[animation="1"] {
  animation-name: example;
  animation-duration: 1000ms;
}

@keyframes example {
  0%   {background-color: white;}
  25%  {background-color: blue;}
  50%  {background-color: white;}
  100% {background-color: blue;}
}
.chatbox-wrapper {
  font-weight: normal;
  position: relative;
  width: 32vw;
  height: 22%;
  font-size: 0.9em;
  border-radius: 12px;
  border: 1px solid #000;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 5000;
  padding: 6px;
}
.chatbox-messages {
  height: 10em;
  overflow-y: auto;
}
.chatbox-input-row {
  display: flex;
  flex-direction: right;
  align-items: center;
  background-color: #fff;
  box-sizing: border-box;
}
.chatbox-message {
  text-align: left;
  padding: 0.3em 1em 0;
  margin: 0.3em 0 0;
  border-top: 1px dashed #ccc;
  vertical-align: middle;
}
.chatbox-input {
  width: calc(100% - 5em);
  height: 5em;
  resize: none;
  z-index: 50000;
}
.chatbox-send {
  margin: 2px;
  height: 4em;
  width: 4em;
}

.central-info {
  width: 100%;
}

.qc-display-box {
  position: fixed; /* make it draggable... eventually */
  width: 60vw;
  height: 40vh;
  top: 5%;
  left: 20%;
  font-size: 1em;
  border-radius: 2px;
  box-sizing: content-box;
  z-index: 5000;
  color: #ff0;
}
.qc-display-box div.qc-text-only {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  width: 60vw;
  height: 40vh;
  border: 2px solid rgba(255,255,255,0.5);
  border-radius: 8px;
  background-color: #00f;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
}
.qc-display-box div.qc-text-only.category {
  font-size: 50px;
  font-variant: small-caps;
  font-weight: bolder;
}
div.qc-text-only {
  text-shadow: 4px 4px #333388;
}

.qc-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.qc-categories button.cat-box {
  flex-basis: calc(25% - 8px);
  min-height: 3em;
  max-height: 4em;
  height: calc(3em + 4px);
  background-color: #00f;
  font-weight: bold;
  padding: 2px;
  vertical-align: middle;
  font-variant: small-caps;
  font-size: 1.4em;
  text-align: center;
  border: 2px solid rgba(255,255,255,0.5);
  border-radius: 8px;
  min-width: 14%;
  max-width: 49%;
  margin: 1px;
  color: #ff0;
}
.qc-categories button.cat-box.played {
  background-color: #ccc;
  color: #888;
  opacity: 70%;
}
button.next-button {
  border-radius: 2px solid #fff;
  background-color: #cfc;
  color: #000;
  height: 2em;
  width: 5em;
  position: relative;
  z-index: 5000;
  margin-top: -2em;
  top: 20px;
}

.qc-host-notes {
  flex-direction: column;
}
.category-small {
  font-size:0.8em;
  text-decoration: underline;
}
.question-preview {
  color: #fff;
}
.host-notes {
  font-size: 0.6em;
  font-style: italic;
}

.shutterable {
  width: 100%;
  position: relative;
  z-index: 20000;
}
.shutterable-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px 0px;
}

#team-1-player-0 {
  position: fixed;
  top: calc(45px);
  left: 5px;
  width: 30vh;
  max-height: 32%;
}
#team-1-player-1 {
  position: fixed;
  top: calc(45px + 32%);
  left: 5px;
  width: 30vh;
  max-height: 32%;
}
#team-1-player-2 {
  position: fixed;
  top: calc(45px + 64%);
  left: 5px;
  width: 30vh;
  max-height: 32%;
}
#team-1-player-3 {
  position: fixed;
  top: calc(45px + 64%);
  left: calc(30vh + 1.5%);
  width: 30vh;
  max-height: 32%;
}

#team-2-player-0 {
  position: fixed;
  top: calc(45px);
  left: calc(100% - 8px - 30vh);
  width: 30vh;
  max-height: 32%;
}
#team-2-player-1 {
  position: fixed;
  top: calc(45px + 32%);
  left: calc(100% - 8px - 30vh);
  width: 30vh;
  max-height: 32%;
}
#team-2-player-2 {
  position: fixed;
  top: calc(45px + 64%);
  left: calc(100% - 8px - 30vh);
  width: 30vh;
  max-height: 32%;
}
#team-2-player-3 {
  position: fixed;
  top: calc(45px + 64%);
  left: calc(100% - 8px - 62vh);
  width: 30vh;
  max-height: 32%;
}
