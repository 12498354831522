
.placard {
   background-color: #fff;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   border: 1px solid #f60;
   border-radius: 6px;
   height: 2.2em;
   width: 100%;
   font-size: 0.8vw;
   overflow: hidden;
   padding: 4px;
}
.placard-narrow {
   width: 50%;
   margin-top: 20%;
}
.placard-big {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #f60;
  border-radius: 20px;
  overflow: hidden;
margin: 30px auto;
padding: 30px;
   width: 90%;
   font-size: 2em;
   font-weight: bold;
}

.dashboard__404 {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: #fff;
}
.dashboard__classContainer {
  display: flex;
  padding: 30px;
  flex-wrap: wrap;
  width: 100vw;
  background-color: #fff;
}
.generic_loading {
  width: 100%;
  height: 100%;
}
.dashboard__available_games {
  width: 95%;
  height: 100%;
  background-color: #cceeff;
  padding: 10px;
  border-radius: 10px;
  margin: 10px auto;
  border: 2px solid #000;
  min-height: 40vmin;
}
.dashboard__available_games table {
  border: 1px solid #000;
  width: 100%;
  margin-top:10px;
}
.dashboard__available_games table tbody tr td {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  background-color: #cfdff0;
  margin: 2px 0;
}
.dashboard__available_games table tbody tr:nth-child(even) td {
  background-color: #cfd0ff
}
.dashboard__available_games table tbody tr td {
  width: 20%
}

.dashboard__available_games span.header,
.announcements span,
.activity span {
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.3em;
  font-variant: small-caps;
}

.announcements,
.activity {
  width: 45%;
  padding: 10px;
  background-color: #ddd;
  border: 2px solid #000;
  border-radius: 10px;
  color: #000;
  margin: 0 auto;
  height: 100%;
  min-height: 200px;
}
.info_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40%;
}

.full {
  width: 100%;
}

.participant_cell {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.participant_group {
  font-variant: small-caps;
  font-weight: bold;
  text-decoration: underline;
}
.participant_block {
  color: #fff;
  flex-basis: 30%;
  border: 1px solid #000;
  padding: 3px;
  font-size: 0.9em;
}
td.narrow_col {

}
.spectator_count {
  background-color: #555;
  font-size: 0.8em;
  color: #fff;
}
div.nickname {
  display: block;

}

.narrower_col {
  width: 5em;
  max-width: 10%;
}
.wider_col {
  width: 10em;
  min-width: 30%;
}

textarea.about-me {
  width: 100%;
  min-height: 6em;
  resize: none;
}
.top-dialog div {
  z-index: 9999999;
  background-color: #ccfffc;
}
