.announcements span {
  margin: 4px auto 0px;
  display: block;
}
.announcement {
  margin-top: 10px 0 20px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #adadad;
  background-color: #fff;
  width: 100%;
  text-align: left;
  font-size: 1.2em;
}
.announcement__informationContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;

}
.announcement__header {
  display: flex;
  align-items: center;
}
.announcement__name {
  font-weight: 600;
  width: 60%;
  text-align: left;
  text-decoration: underline;
}
.announcement__date {
  color: #424242;
  font-weight: normal;
  font-size: 0.8em;
  width: 40%;
  text-align: right;
  text-decoration: underline;
}
.announcement__imageContainer > img {
  height: 50px;
  width: 50px;
  border-radius: 9999px;
}
.announcement__content {
  margin-top: 1em;
  font-size: 0.9em;
}
