html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  box-sizing: border-box;
}
.box404 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 90%;
  margin-top: -5%;
  margin: 5% auto;
  background-color: #7b9dcf;
  color: #ff0;
  font-size: 25em;
  padding: 10px;
  border: 2px solid #000;
  border-radius: 50px;
  font-family: 'digital-clock-font'
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/public/stage_background2.jpg');
  background-size: 100%;  
  background-position: top left;
  background-repeat: no-repeat;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.home {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home__container {
  background-color: #f5f5f5;
  box-shadow: 0px 0px 2px -1px black;
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.home__login {
  margin-top: 30px;
  padding: 20px;
  background-color: #2980b9;
  font-size: 18px;
  color: white;
  border: none;
  text-transform: uppercase;
  border-radius: 10px;
}
.home__marquee {
  margin: 0 auto;
  font-size: 2em;
  font-weight: bolder;
  font-variant: small-caps;
  display: block;
  width: 95%;
  text-align: center;
  margin-bottom: 10px;
  border: 2px solid #336799;
  border-radius: 9px;
  background-color: #99c7e9;
}

button.big-button {
  font-size: 1.2em;
  border: 2px solid #690;
  padding: 10px;
  border-radius: 20px;
}
button.big-button-red {
  font-size: 1.2em;
  border: 2px solid #960;
  padding: 10px;
  border-radius: 20px;
}
