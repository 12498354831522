
.led-timer {
  width: 100%;
  border: 2px solid #337699;
  border-radius: 10px;
  padding:4px;
  background-color: #000;
  margin: 5px auto;
}
.led-timer .timer-counter {
  font-family: 'digital-clock-font';
  background-color: #000;
  font-size: 2vmax;
  font-weight: bold;
  color: #ff0; /* if not overridden in code */
  margin: 4px auto;
  border: 1px solid #555;
  background-color: #222;
}
.led-timer .timer-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}
.led-timer .timer-buttons .time-rare {
  background-color: #a58;
  height: 50%;
  padding: 0px;
  max-width: 20%;
  margin: 0 5% 5px;
  border: 2px solid #f00;
  color: #500;
}
.led-timer .timer-buttons .time {
  background-color: #8cf;
  border: 2px solid #06d;

}
.led-timer .timer-buttons .stop {
  background-color: #f66;
}
.led-timer .timer-buttons .start {
  background-color: #6f6;
}
.led-timer .timer-buttons .pause {
  background-color: #ff6;
}
.led-timer .timer-buttons button {
  flex-basis: 30%;
  height: 49%;
  width: 100%;
  font-size: 0.9vmax;
  margin: 2px;
  text-transform: none;

}
