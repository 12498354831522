.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.next-button {
	width: 10em;
	height: 5em;
	font-size: 3em;
}

#largeVideoWrapper {
  height: 100%;
  width: 100%;
}
.video-all {
  border:2px solid #ccc;
  width: 33%;
  height: 50%;
  position: relative;
  display: block;
  float: left;
  background-image: url('/public/retrotv.svg');
  background-size: 100% 100%;
  clear: none;
  z-index: 500;
}

.video-stream-wrapper {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  height:100%;
}
.video-stream {
  height: 100%;
  max-height: 80%;
  display: flex;
  width: 100%;
  z-index: 1000;
  justify-content: center;
  margin:0 auto;
}

.track-controls {
  border: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

}
.track-controls button {
  margin: 0 5px;
  border-radius: 5px;
  width: 42%;
  padding: 1px 2px;
  height: 40%;
  background-color: #336799;
  z-index: 5000;
  color: #fff;
  font-size: 0.8em;
  overflow: display;
}
.stream-controls-wrapper {
  z-index: 6000;
  position: absolute;
  overflow: display;
  color: #fff;
  background-color: #f76;
  width: 100%;
  height: 100%;
}

.screenglitch {
  width: 100%;
  height: 100%;
}

.unmuted-mic {
  height: 2em;
  z-index: 555500;
  position: relative;
  float: right;
  margin-top: -3em;
  margin-right: .5em;
  opacity: 50%;
}
.muted-mic {
  height: 2em;
  z-index: 555500;
  position: relative;
  margin-top: -3em;
  margin-right: .5em;
  float: right;
  opacity: 50%;
}

.video-waiter-spin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  position: relative;
  z-index: 500;
  zoom: 1;
  top: 70px;
  margin: 0 auto;
  overflow: hidden;
}
