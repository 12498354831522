div#gameroom {
  height: 100vh;
  width: 100%;
  background-size: 100%;
  background-color: #000;
  /* background-image: url('/public/stage_background2.jpg'); */
  background-image: url('/public/stage_game.jpg');
}
body {
  /* overflow: hidden; */
}

div.contestant-podium {
  border:2px solid #ccc;
  width: 100%;
  height: 35%;
  display: block;
  border-radius: 30px;
  background-image: url('/public/retrotv.svg');
  background-size: 350px;
  background-position: center;
  margin: 0 10px
}

/*
#contestants {
  position: fixed;
  top: 60%;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  flex-wrap: nowrap;
  height: 45%;
  flex-direction: row;
} */

#question_box {
  position: fixed;
  top: 6%;
  left: 250px;
	background-color: #00f;
	font-size: 6vmin;
	color: yellow;
	font-variant: small-caps;
	padding: 20px;
  font-weight: bold;
	text-align: center;
	height: 40%;
  vertical-align: middle;
	width: 68%;
	border: 5px solid #000;
  font-family: "KorinnaStd-Bold";
  text-shadow: 4px 4px #000;
  border-radius: 20px;
  margin:5px;
}

.correct-answer {
	z-index: 100;
	position: absolute;
	left: 25%;
	top: 25%;
	width: 50%;
	height: 50%;
	border: 5px solid #000;
	background-color: #090;
	font-size: 10em;
	color: #ffffff;
}


/* TV noise */
.noise-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  z-index: 10;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%);
  }
}

.noise {
  position: absolute;
  top: -500px;
  right: -500px;
  bottom: -500px;
  left: -500px;
  background: transparent url("/public/noise.png") 0 0;
  background-size: 320px 320px;
	opacity: .45;
	animation: noise 1s steps(8,end) infinite both;
}

@keyframes noise {
  0% {
    transform: translateX(0px,0px); }
  10% {
    transform: translate(-100px, 100px);
  }
  20% {
    transform: translate(150px, -100px);
  }
  30% {
    transform: translate(-100px,100px);
  }
  40% {
    transform: translate(100px, -150px);
  }
  50% {
    transform: translate(-100px, 200px);
  }
  60% {
    transform: translate(-200px, -100px);
  }
  70% {
    transform: translateY(50px, 100px);
  }
  80% {
    transform: translate(100px, -150px);
  }
  90% {
    transform: translate(0px, 200px);
  }
  100% {
    transform: translate(-100px, 100px);
  }
}

#robot_host {
  width: 200px;
  height: 200px;
  background-image: url('/public/robothost.png');
  background-size: 200px 200px;
}
#robot_marquee {
  background-color: #9fc8d3;
  font-weight: bold;
  height:20px;
  color: #000;
  width: 200px;
}
#robot_wrapper {
  position: fixed;
  left: 20px;
  top: 60px;
  border: 2px solid #3f98c3;
  border-radius:20px;
  width: 200px;
  height: 220px;
  overflow: hidden;
}
#console {
  position: fixed;
  left: 550px;
  width: 20%;
  height: 45%;
  background-color: #fff;
  color: #000;
}
.leave-button {
  z-index: 229000;
  border: 2px solid #000;
  background-color: #f99;
  color: #fff;
}

div.scoreboard {
  /* border: 2px solid #ccf; */
  width: 95%;
  height: 20%;
  position: relative;
  margin: 3px auto;
  vertical-align: bottom;
/*  background-color: #f9f9f9; */
}

div.score_text {
  font-size: 4vh;
  font-weight: bold;
  font-family: 'Courier';
  color: #000;
}
.answer {
  margin-top: 20px;
  width: 24%;
  margin-right: 1%;
  height: 120px;
  border: 2px solid #fff;
  border-radius: 10px;
  vertical-align: middle;
  padding-top: 20px;
}
.answer-correct-true {
  background-color: #336799;
}
.answer-correct-false {
  background-color: #336799;
}
/* #answer_boxes {
  flex-flow: row;
  flex-basis: auto;
  flex-wrap: nowrap;
  display: flex;
}
#answer_box_wrapper {
  width: 100%;
  height: 210px;
  font-size: 0.5em;
} */

.empty-container-message {
  position: relative;
  vertical-align: middle;
  margin: 0 auto;
  top: 45%;
  z-index: 2000;
  background-color: #fff;
  border: 1px solid #f60;
  border-radius: 20px;
  height: 2em;
  padding: 15px 20px 0px;
  font-size: min(0.8vw, 30px);
  overflow: hidden;
}
div.team-header {
  font-size: 1em;
  width: 9%;
  font-weight: bold;
  top: 1%;
  background-color: #fff;
}
#team-header-1 {
  position: fixed;
  left: 18%;
  top: calc(45px + 49vh);
}
#team-header-2 {
  position: fixed;
  right: 18%;
  top: calc(45px + 49vh);
}

.empty-seat-graphic {
  width: 100%;
  height: 100%;
  background-color: hsla(180,40%,30%,0.2);
}

/* the column for spectators */
div.spectators {
  width: 10vw;
  height: 80vh;
  scroll: auto;
}
#spectators {
  max-width: 15vw;
  font-size: 15px;
}

div.spectator-slot {
  border:2px solid #ccc;
  border-radius: 20px;
  overflow: hidden;
  background-color: rgba(0,0,0,0.8);
  box-sizing: border-box;
  width: 100%;
  height: 1%;
}
div.spectator-slot .video-stream-wrapper {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
}
.spectator_label {
  z-index: 1000;
  background-color: rgba(200,190,250,0.5);
  font-size: 0.6vw;
  width: 100%;
  border-radius: 5px;
  margin: 2px auto;
  height: 0.8vw;
}
.spectator_header {
  width: 100%;
  border-bottom: 2px solid #55f;
  font-size: 1.5em;
  background-color: rgba(210,210,240,0.9);
}
.spectator_column {
  overflow-y: auto;
  width: 100%;
  height: 100%;
}
